<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Stemmingmakers` : `Stemmingmakers` }}</template>
  </metainfo>
  <router-view v-slot="{ Component, route }">
    <transition name="fade" mode="out-in">
      <div :key="route.name" class="w-screen">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
</template>

<script>
import 'animate.css';
import {useHead} from '@unhead/vue';

export default {
  setup () {
    useHead({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: 'GT Super Display';
  src:  url('assets/fonts/GT-Super-Display-Super.woff2') format('woff2'),
  url('assets/fonts/GT-Super-Display-Super.woff') format('woff');
}
@font-face {
  font-family: 'GT Walsheim';
  src:  url('assets/fonts/GT-Walsheim-Regular.woff2') format('woff2'),
  url('assets/fonts/GT-Walsheim-Regular.woff') format('woff');
}

#app {
  font-family: 'GT Walsheim', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

h1,h2,h3,h4,h5{
  font-family: "GT Super Display", sans-serif !important;
  word-break: auto;
  hyphens: auto;
  &:not([class*=mb-]):not([class*=my-]){
    margin-bottom: 0.5em;
  }
  &:not([class*=leading]){
    line-height: 1.2em;
  }
}

.container{
  @apply mx-auto relative w-full h-full;
  @screen <lg{
    padding-left:5%;
    padding-right:5%;
  }
  max-width: 1280px !important;
}

.center-xy{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center-x{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.center-y{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rounded-img{
  border-radius: 50px;
}

.sticky{
  position: sticky;
}

.btn{
  border: 1px solid currentColor;
  border-radius: 1.5em;
  padding: 0.6em 1.4em;
}

.text-xxl, h1{
  @apply xl:text-80 lg:text-50 md:text-50 sm:text-50 xs:text-50;
}
.text-xl, h2{
  @apply xl:text-50 lg:text-40 md:text-40 sm:text-40 xs:text-40;
}
.text-lg, h3{
  @apply xl:text-40 lg:text-32 md:text-28 sm:text-28 xs:text-24;
}
.text-md,  h4, .intro-text{
  @apply xl:text-26 lg:text-26 md:text-26 sm:text-20 xs:text-20;
}
.text-sm, p, h5{
  @apply min-md:text-20 max-sm:text-18;
}
.text-xs, small{
  @apply min-md:text-18 sm:text-16 xs:text-15;
}

.avatar{
  width:140px;
  height:140px;
  object-fit:cover;
  border-radius:100%;

  @screen md{
    width:100px;
    height:100px;
    margin-right:20px
  }

  @screen <sm{
    width:80px;
    height:80px;
    margin-right:10px
  }
}

.block{
  display:block;
}
</style>
