<template>
  <top-nav :class="'article article--'+(article.id % 7 + 1)"></top-nav>
  <div :class="'article article--'+(article.id % 7 + 1 )">
    <div class="h-300"></div>
    <div class="container">
      <h1 class="z-1 article__highlight text-80 xl:text-110 max-md:text-50 w-full text-center leading-1">
        <span class="animate__animated animate__fadeInDown">
          {{ article.title }}
        </span>
      </h1>
      <p class="text-26 text-center py-50 min-md:py-80">{{ article.custom_author }}</p>
    </div>
    <img :src="article.cover_image" class="max-h-screen object-cover w-full animate__animated animate__fadeIn" alt="">
    <div class="article-container m-auto" v-html="article.content"></div>
    <div class="container min-md:flex animate__animated animate__fadeInUpSmall mb-60 max-sm:mb-40">
        <router-link v-if="article.previous" exact :to="{name:'article', params:{slug: article.previous.slug ?? '/'}}" class="block w-full text-sm hover:article__highlight flex-1 py-20"><i class="far fa-arrow-left mr-20"></i> {{ article.previous.title }}</router-link>
        <router-link v-if="article.next" exact :to="{name:'article', params:{slug: article.next.slug ?? '/'}}" class="block w-full text-sm hover:article__highlight flex-1 text-right py-20 mb-20"><span>{{ article.next.title }}</span> <i class="far fa-arrow-right ml-20"></i></router-link>
    </div>
    <hr class="absolute article__parapraph container">

  </div>
  <contact-footer :class="'article article--'+(article.id % 7 + 1)"></contact-footer>
</template>

<script>
import {useHead, useSeoMeta} from "@unhead/vue";
import axios from "axios";

export default {
  name: 'ArticlePage',
  data() {
    return {
      article: {},
    }
  },
  methods:{
    get(){
      axios.get(this.apiDomain+'/api/stemmingmakers/articles/'+this.$route.params.slug).then(response => {
        this.article = response.data;

        useHead({
          title: () => this.article?.title ?? undefined,
          meta: [
            {name: 'description', content: this.article?.teaser ?? undefined},
          ]
        });

        useSeoMeta({
          ogSiteName: 'Sitemmingmakers',
          ogType: 'article',
          title:  this.article?.title ?? undefined,
          twitterTitle:  this.article?.title ?? undefined,
          ogTitle: this.article?.title ?? undefined,
          description: this.article?.teaser ?? undefined,
          twitterDescription: this.article?.teaser ?? undefined,
          ogDescription: this.article?.teaser ?? undefined,
          ogImage: this.article?.grid_image_wide_2x ?? 'https://www.stemmingmakers.nl/img/3.b36d9ed4.jpg',
          ogUrl: new URL(this.$route.path, window.location.origin).href,
          twitterCard: 'summary',
          twitterSite: 'Stemmingmakers'
        });

        // Router-view with key makes blog>blog possible but kills animation so for this purpose animation is added customly
        document.getElementById('app').setAttribute('class', 'fade-enter-active fade-enter-to');
      }).catch(error => {
        this.$router.push('/');
        console.log(error);
      });
    },
    getDelay(){
      setTimeout(timer => {
        this.get(timer);
      }, 500);
    }
  },
  watch: {
    '$route.params.slug'() {
      this.getDelay();
    }
  },
  created(){
    this.get();
  }
}
</script>

<style lang="scss">

.article{
  &-container{
    @apply py-100 max-sm:py-50;
  }
  p, h4{
    @apply mx-auto;
    width: 750px;
    max-width: 90vw;
  }
  .intro-text{
    line-height: 1.2em !important;
    margin-bottom: 2em;
  }
  .quote{
    @apply py-20 min-md:text-40 min-md:py-40;
    font-family: "GT Walsheim", sans-serif !important;
  }

  p{
    margin-bottom:2em;
    margin-top: 1em;
  }

  $patterns:
      1 "pink-dull" "purple-dull" "purple",
      2 "yellow" "orange-dull" "orange",
      3 "brown-light" "purple-dull" "purple",
      4 "purple-dull" "blue-light" "white",
      5 "orange" "yellow-dull" "yellow",
      6 "grey" "brown-light" "yellow",
      7 "blue-light" "grey" "blue";


  @each $k, $bg, $paragraph, $highlight in $patterns {
    &--#{$k}{
      @apply bg-#{$bg} text-#{$paragraph};
      .article__paragraph{
        @apply text-#{$paragraph};
      }
      .article-container{
        a{
          @apply underline text-#{$highlight};
          &:hover{
            @apply text-#{$paragraph};
          }
        }
      }
      hr{
        @apply border-#{$paragraph};
      }
      .article__highlight, .intro-text, .hover\:article__highlight:hover {
        @apply text-#{$highlight};
      }
    }
  }
}

.caroussel{
  @apply text-center min-md:grid mx-auto xl:px-100 lg:px-50 md:px-30 sm:px-20;
  width: 1300px;
  max-width:90vw;
  grid-template-columns: 1fr 1fr;
  grid-gap:40px;
  @screen xl{
    grid-gap:150px;
  }

  img{
    @apply w-full mx-auto mb-20 object-cover;
    max-width:500px;
    border-radius: 50px;
  }

  &__left, &__right{
    @apply mb-20 mt-40 min-md:mt-60 min-md:mb-40;
  }
}
</style>
