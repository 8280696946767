<template>
  <top-nav></top-nav>

  <!-- Hero -->
  <div class="hero top-0">
    <div class="container flex py-50" style="min-height: 620px;">
      <div class="center-xy z-1 text-center w-full max-sm:px-20">
        <h1 class="text-white text-80 xl:text-110 max-md:text-50 mb-40 mx-auto text-center leading-1">
          <span class="inline-block animate__animated animate__fadeInDown animate__delay-1s">
            Weet wat jongeren beweegt
          </span>
        </h1>
        <div @click="scrollDown" class="cursor-pointer min-md:hidden inline btn text-sm text-white inline-block animate__animated animate__fadeIn animate__delay-2s"><i class="far fa-arrow-down mr-10"></i> Over ons</div>
      </div>
    </div>
    <video v-if="video"
           poster="./../assets/images/3.jpg"
           class="-z-1 top-0"
           ref="video"
           muted loop playsinline autoplay>
      <source :src="video" type="video/mp4" />
    </video>
  </div>

  <!-- About -->
  <div class="bg-yellow max-sm:pb-50">
    <sticky-box>
      <template v-slot:introduction>
        <span class="text-red text-md inline-block animate__animated animate__fadeInLeft">
          Wij zijn Stemmingmakers, het onderzoeksbureau van Scholieren.com en Studenten.com.<br>
          <br>
          Stemmingmakers geeft inzicht in de wereld van jongeren. Omdat we het belangrijk vinden dat er naar ze wordt geluisterd. Jongeren hebben eigenlijk best wel goede ideeën, opvattingen en gedachten. Daar moet je wat mee doen!
        </span>
      </template>
      <template v-slot:content>
        <img src="./../assets/images/2.jpg" class="rounded-img animate__animated animate__fadeInUpSmall animate__slow" alt="">
        <div class="text-center text-red text-italic mt-10 text-xs animate__animated animate__fadeInUpSmall animate__slow">Credits: Bregje Pontzen, <a href="https://lichting.nu" target=_blank class="underline">Lichting.nu</a></div>
        <div v-for="(description, service ) in services" :key="service" class="animate__animated animate__fadeInUpSmall animate__slow">
          <h3 class="text-red-dull text-lg mt-40">{{ service }}</h3>
          <p class="text-red-dull text-sm">{{ description }}</p>
        </div>
      </template>
    </sticky-box>
  </div>

  <!-- Data -->
  <div class="bg-white max-sm:pb-40">
    <sticky-box :inverse="true">
      <template v-slot:introduction>
        <span class="inline-block animate__animated animate__fadeInRight">
          <h3 class="text-purple-dull text-xl mb-20">Van inzicht naar data</h3>
          <span class="text-purple-dull text-sm">
           Benieuwd naar wat jongeren ergens van denken? Vraag het ze gewoon. Wij zorgen voor een goede onderzoeksopzet, grondige analyse en advies op maat.
          </span>
        </span>

      </template>
      <template v-slot:content>
        <img src="./../assets/images/3.jpg" class="rounded-img animate__animated animate__fadeInUpSmall animate__slow" alt="">
        <div class="text-center text-purple text-italic text-xs mb-40 mt-10 animate__animated animate__fadeInUpSmall animate__slow">Credits: Bregje Pontzen,<a href="https://lichting.nu" target=_blank class="underline">Lichting.nu</a></div>
        <div class="animate__animated animate__fadeInUpSmall animate__slow">
          <h3 class="text-purple text-xxl min-lg:mt-40 mb-10">33%</h3>
          <p class="text-purple text-sm mb-10"> van de studiekiezers kijkt studiekeuze-content op TikTok (en op YouTube daalde dit percentage van 55% naar 37,8%)
          </p>
          <p class="text-purple-dull text-sm mb-20">Uit: studiekeuzeonderzoek 2022 & 2024</p>
        </div>
        <div class="animate__animated animate__fadeInUpSmall animate__slow">
          <h3 class="text-purple text-xxl min-lg:mt-40 mb-10">18,3%</h3>
          <p class="text-purple text-sm mb-10"> heeft (bijna) altijd een condoom op zak
          </p>
          <p class="text-purple-dull text-sm mb-20">Uit: condoomonderzoek, december 2024</p>
        </div>
        <div class="animate__animated animate__fadeInUpSmall animate__slow">
          <h3 class="text-purple text-xxl min-lg:mt-40 mb-10">40%</h3>
          <p class="text-purple text-sm mb-10"> van de jongeren zou zich schamen als ze online worden opgelicht</p>
          <p class="text-purple-dull text-sm mb-20">Uit: 'Een onderzoek naar ervaringen van middelbare scholieren met online oplichting & cybercrime' in opdracht voor het CCV, juni 2024</p>
        </div>
      </template>
    </sticky-box>
  </div>

  <!-- Articles -->
  <div class="bg-brown-dark max-sm:pb-10">
    <sticky-box>
      <template v-slot:introduction>
        <span class="inline-block animate__animated animate__fadeInLeft">
          <h3 class="text-brown-light text-xl mb-20">Artikelen</h3>
          <span class="text-brown-light text-sm">
            Onderzoek van Stemmingmakers levert vaak interessante inzichten op. Deze artikelen verschenen naar aanleiding van onderzoeksopdrachten (of onze eigen nieuwsgierigheid).
          </span>
        </span>
      </template>
      <template v-slot:content>
        <router-link :to="{name:'article', params:{slug: article.slug}}" v-for="article in articles" :key="article.id" class="block pb-60 animate__animated animate__fadeInUpSmall animate__slow">
          <div class="w-full overflow-hidden rounded-img mb-30 ">
            <img :src="article.grid_image_wide" :srcset='article.grid_image_wide_2x + " 2x"' class="w-full hover:scale-105 ease-in-out duration-500" alt="">
          </div>
          <h3 class="text-yellow text-lg mb-0">{{ article.title }}</h3>
          <p class="text-yellow text-sm mt-10 mb-20">{{ article.teaser }}</p>
          <a class="text-yellow text-sm btn hover:text-blue inline-block">Bekijk</a>
        </router-link>
      </template>
    </sticky-box>
  </div>

  <!-- Questions -->
  <div class="bg-purple-dull max-sm:pb-50">
    <sticky-box :inverse="true">
      <template v-slot:introduction>
        <span class="inline-block animate__animated animate__fadeInRight">
        <h3 class="text-pink-dull text-xl mb-20">Welke vraag je ook hebt</h3>
        <span class="text-pink-dull text-sm">
          Met Stemmingmakers vind je het antwoord. Stemmingmakers legt op een passende manier je vraag, idee of uitdaging voor aan jongeren. Scholieren, studenten of een combinatie.<br>
          <br>
          We werven deelnemers via verschillende kanalen. Onder andere via onze websites Scholieren.com en Studenten.com, met een bereik van 1,5 miljoen unieke bezoekers per maand.
        </span>
        </span>
      </template>
      <template v-slot:content>
        <img src="./../assets/images/5.jpg" class="rounded-img animate__animated animate__fadeIn" alt="">
        <div class="text-center text-blue-light text-italic text-xs mb-40 mt-10 animate__animated animate__fadeIn">Credits: Bregje Pontzen,<a href="https://lichting.nu" target=_blank class="underline">Lichting.nu</a></div>
        <div v-for="(question, q) in questions" :key="q" class="animate__animated animate__flipInX">
          <h3 class="text-blue-light text-lg mt-40">{{ question }}</h3>
        </div>
      </template>
    </sticky-box>
  </div>

  <!-- Clients -->
  <div class="bg-orange max-sm:pb-50">
    <sticky-box>
      <template v-slot:introduction>
        <span class="inline-block animate__animated animate__fadeInLeft">
        <h3 class="text-brown text-xl mb-20">Opdrachtgevers</h3>
        <span class="text-brown text-sm">
          We werken met de leukste opdrachtgevers van Nederland, van onderwijsinstellingen tot mediabureaus. Sta je er nog niet tussen? Neem contact op!
        </span>
        </span>
      </template>
      <template v-slot:content>
        <div v-for="(client, key) in clients" :key="key" class="mt-40 animate__animated animate__fadeInUpSmall animate__slow">
          <h3 class="text-yellow text-lg">{{ key }}</h3>
          <p class="text-yellow text-sm mt-20">{{ client }}</p>
        </div>
      </template>
    </sticky-box>
  </div>

  <!-- Contact -->
  <contact-footer class="bg-blue-light"></contact-footer>

</template>

<script>
import {useHead, useSeoMeta} from "@unhead/vue";
import axios from "axios";
import animation from './../mixins/animation';

export default {
  name: 'HomePage',
  data() {
    return {
      articles: [],
      configs: {},
      video: null,//'https://cdn.sanity.io/files/1gdhddkw/production/aa0a204deac827290969751fab7f664b0b9fe485.mp4',
      services: {
        'Vragenlijst': 'Doe online onderzoek naar de mening van jongeren over een onderwerp, product of dienst. Als je wil verzorgen we het hele proces; het opstellen van de vragenlijst, het werven van respondenten en de analyse en rapportage.',
        'Groepsgesprek': 'Raak in gesprek met je doelgroep! Werkt goed als voorbereiding op een vragenlijst of om na afloop de diepte in te gaan. We organiseren een (online) groepsgesprek en nodigen een diverse groep jongeren uit. Eventueel leiden we het gesprek en verzorgen we een samenvatting of rapportage.',
        'Brainstorm': 'Ben je bezig met een jongerenproject- of campagne, maar weet je nog niet helemaal hoe je dit moet vormgeven? We organiseren een (online) brainstorm met een groep gemotiveerde, creatieve jongeren. Daarmee krijg je inzichten en ideeën, direct vanuit je doelgroep.',
        'Interview': 'Sommige onderwerpen of thema’s vragen om 1-op-1 gesprekken. We zoeken geschikte deelnemers, plannen de gesprekken en zorgen voor een goede voorbereiding. Als je wil doen we ook de gesprekken zelf en maken we een nette rapportage.',
        'Maatwerk': 'Ben je nieuwsgierig naar wat jongeren ergens van vinden? Dan helpen we je, ook als je vraag niet precies in één van bovenstaande categorieën valt. Zo hebben we de NTR geholpen om een betere filmset te ontwerpen, door foto’s van échte tienerslaapkamers te verzamelen en het ministerie van VWS bij de vraag hoe je het best over corona praat met jongeren. Heb jij ook een vraag? Laat het ons weten!',
      },
      clients:{
        'Ministerie van Justitie en Veiligheid': 'We hielpen het ministerie en het centrum voor criminaliteitspreventie en veiligheid om inzicht te krijgen in het uitgaansleven van jongeren tijdens corona.',
        'CPNB':'Het CPNB vroeg ons om een onderzoek uit te zetten naar hoe zij de campagne voor de  Boekenweek voor Jongeren nog leuker en effectiever kunnen maken.',
        'Hogeschool Inholland':'Tijdens de coronaperiode vroeg Hogeschool Inholland zich af hoe zij hun (online) open dagen het best konden organiseren. Wij hielpen ze die vraag te beantwoorden door een online brainstorm te organiseren met een groep studiekiezers.',
        'Trouw':'Samen met een journalist van Trouw werkten we aan een artikel over scholieren en crypto. Via een korte poll onderzochten we hoeveel scholieren (minderjarigen) zelf bezig zijn met de handel in crypto.'
      },
      questions:[
        'Hoe kiezen scholieren een vervolgopleiding?',
        'Waar en wanneer kopen jongeren hun schoolspullen?',
        'Hoe denken jongeren over de aanpak van seksueel grensoverschrijdend gedrag?',
        'Met wat voor content spreek je jongeren aan op TikTok?',
        'Wat is het imago van het beroep \'leraar\' onder jongeren?',
        'Lezen jongeren nog voor hun plezier?',
        'Waarom kiezen veel jongeren voor een tussenjaar?',
        'Hoe kijken jongeren naar werk in de toekomst?'
      ]
    }
  },
  created(){
    useHead({
      title: () => 'Stemmingmakers',
      meta: [
        {name: 'description', content: this.article?.teaser ?? undefined},
      ]
    });

    const description = 'Wij zijn Stemmingmakers, het onderzoeksbureau van Scholieren.com en Studenten.com. Stemmingmakers geeft inzicht in de wereld van jongeren.';

    useSeoMeta({
      ogSiteName: 'Stemmingmakers',
      ogType: 'website',
      title: 'Stemmingmakers',
      twitterTitle:  'Stemmingmakers',
      ogTitle:'Stemmingmakers',
      description: description,
      twitterDescription: description,
      ogDescription: description,
      ogImage: 'https://www.stemmingmakers.nl/img/3.b36d9ed4.jpg',
      ogUrl: 'https://www.stemmingmakers.nl',
      twitterCard: 'summary',
      twitterSite: 'Stemmingmakers'
    });

    axios.get(this.apiDomain+'/api/stemmingmakers/articles').then(response => {
      this.articles = response.data;
    });
    axios.get(this.apiDomain+'/api/stemmingmakers/configs').then(response => {
      this.configs = response.data;
      let isMobile = window.innerHeight <= 1000 && window.innerWidth <= 600;
      this.video = this.configs.filter(item => item.slug === 'stemmingmakers-hero-video'+(isMobile ? '-mobile' : ''))[0].value;

      this.animation();
    }).catch(error => {
      console.log(error);
    });
  },
  methods:{
    animation,
    scrollDown(){
      window.scrollTo({
        top: window.innerHeight - 20,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.hero{
  height:100vh;
  width: 100%;

  > video{
    position: absolute;
    width: 100%;
    height:100vh;
    object-fit: cover;
    pointer-events: none;

    &::-webkit-media-controls-start-playback-button, &::-webkit-media-controls-panel, &::-webkit-media-controls {
      display: none !important;
      opacity: 1 !important;
    }
  }

  h1{
    max-width: 660px;
    hyphens:none;
    @screen xl{
      max-width:900px;
    }
  }
}
</style>
