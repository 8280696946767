import './assets/tailwind.scss'
import router from './router';
import {createApp, h} from 'vue';
import App from './App';
import {createHead, useHead} from '@unhead/vue';
import TopNav from './components/TopNav';
import ContactFooter from './components/ContactFooter';
import StickyBox from "@/components/StickyBox";

const app  = createApp({
    render: ()=>h(App)
});

app.use(router);
app.mixin({
    data() {
        return {
            apiDomain: process.env.VUE_APP_SERVICE_URL
        };
    },
    created() {
        useHead({
            titleTemplate(title) {
                return title ? `${title} - Studenten.com` : 'Studenten.com';
            }
        });
    },
});

const head = createHead();
app.use(head);

app.component("TopNav", TopNav); // global registration - can be used anywhere
app.component("ContactFooter", ContactFooter); // global registration - can be used anywhere
app.component("StickyBox", StickyBox); // global registration - can be used anywhere


app.mount("#app")
